import React from "react";
import "./Top.css";

//IMAGE
import BLCLogo from "./image6.jpeg";

export const Top = () => {
  return (
    <div id="Top1">
      <img className="LogoImg" src={BLCLogo} alt="BLC Logo" />
    </div>
  );
};
